
.block{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list{
    list-style: none;
    margin: 4rem 0 0 0;
}

