@use "styles/globals/placeholders" as *;
@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';


.block{
    margin: -1rem 0 3rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid var(--ice);
}

.group{
    display: flex;
    width: 50%;
    flex-direction: column;
    margin: 0 0 2rem 0;
    padding-right: 2rem;

    &:nth-child(2n){
        padding-right: 0;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        padding: 0;
    }
}

.fullGroup{
    width: 100%;
    padding: 0;
}

.heading{
    text-transform: capitalize;
    margin-bottom: .5rem;
    font-size: 1.4rem;
    @extend %truncate;
}

.list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

.disabledItem{
    color: #ccc !important;
    border-color: #ccc !important;
    pointer-events: none;
    position: relative;

    & img{
        opacity: .15;
    }

    &:hover{
        cursor: not-allowed;
    }

    &::before{
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        margin-right: -20px;
        width: 42px;
        height: 1px;
        background-color: #ccc;
        transform: rotate(45deg);

    }
}

.item{
    margin: 0 .75rem .75rem 0;

    &:last-of-type{
        margin-right: 0;
    }
}

.circle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--indigo);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    color: var(--indigo);
    font-weight: 400;
    line-height: 1;
    font-size: 1.2rem;
    box-shadow: var(--box-shadow-sm);

    &:hover{
        border-color: var(--link_color);
        background-color: var(--white);
        text-decoration: none;
        color: var(--link_color);
    }
}

.selected{
    &.circle, &.pill{
        background-color: var(--ocean);
    }
}

.pill{
    @extend .circle;
    width: auto;
    height: 40px;
    border-radius: 99em;
    padding: .5rem 1rem;
}

.image{
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &.active{
        border: 2px solid var(--terracotta);
    }
}

.active{
    background-color: var(--teak25);
    border-color: var(--terracotta);
    color: inherit;

    &:hover{
        @extend .active;
    }
}

