@use 'styles/globals/breakpoints';

.block{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--standard-width);
}

.heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.list{
    list-style: none;
    margin: 0 0 5rem 0;
    width: 75%;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
    }
}


.noQuestionsMsg{
    text-align: center;
}

