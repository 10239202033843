@use 'styles/globals/breakpoints';


.block{
    white-space: pre-line;
    font-style: italic;
    font-size: 1.4rem;
    letter-spacing: 0px;
    color: #9B9B9B;
    bottom: 2rem;
    line-height: 1.5;
    margin: 2rem 0 0 0;
    text-align: center;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        bottom: 0px;
        padding: 16px 64px 0px 32px;
    }
}

