@use 'styles/globals/placeholders' as *;


.block{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    padding: 2rem 0 0 0;
    color: var(--terracotta);
}

.heading{
    @extend %shipping_heading;
}

.icon{
    @extend %shipping_icon;
}

