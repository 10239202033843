@use 'styles/globals/placeholders' as *;
@use 'styles/globals/breakpoints';


.block{
    display: flex;
    align-items: flex-start;
    padding: 2rem 0 0 0;

    & svg{
        margin-top: -8px;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        align-items: center;
        padding: 0;
    }
}


.heading{
    @extend %shipping_heading;
}

.icon{
    @extend %shipping_icon;
}

