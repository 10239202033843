@use 'styles/globals/breakpoints';

.block{
    width: 100%;
    height: 100%;
    inset: 0;
    position: fixed;
    z-index: 999;
    background-color: rgba(#111, .95);
    border: none;
    padding: 0;
}

.close{
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    z-index: 5;
    
    & svg{
        fill: var(--white);
        width: 50px;
        height: 50px;
    }
}

.thumbCntr{
    position: relative;
    display: flex;
    align-items: center;

    &::after{
        content: "";
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(0deg, #1d1d1d 50%, rgba(34, 34, 34, 0) 100%);
        z-index: 9;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        justify-content: flex-end;
        align-items: flex-end;
        align-content: flex-end;
        height: 100vh;

        &::after{
            top: 155px;
            bottom: auto;
            transform: rotate(180deg);
        }
    }
}

.photoList{
    margin: 0;
    padding: 2rem 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 95vh;
    overflow-y: auto;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        flex-direction: row;
        flex-wrap: wrap;
        height: 75vh;
        width: 100vw;
        overflow-x: hidden;
    }
}

.photoItem{
    margin-bottom: 2rem;
    height: auto;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        margin: 0;
    }
}

.photo{
    height: 90% !important;
    object-fit: cover;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: none;
    }
}

.button{
    position: relative;
    width: 200px;
    height: 200px;
    background-color: transparent;
    border: 5px solid transparent;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        height: auto;
    }
}

.activeButton{
    border-color: var(--white);
}



.largePhotoCntr{
    position: relative;
    padding: 0 2rem;
}


.largePhotoCntr{
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
}



.header{
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 0 4rem 0rem;
    z-index: 1;
    position: absolute;
    background: linear-gradient(0deg, rgba(34, 34, 34, 0) 0%, rgba(#1d1d1d, .75) 40%);

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 2rem
    }
}


.heading{
    color: var(--mist);
    margin-bottom: 5px;
}

.headerMeta{
    display: flex;
    align-items: center;
}

.user{
    text-transform: capitalize;
    font-size: 1.4rem;
    letter-spacing: normal;
}

.text{
    color: var(--mist);
    font-size: 1.4rem;
}

