@use 'styles/globals/placeholders' as *;


.block{
    display: flex;
    background-color: var(--mist);
    padding: 4rem;
    margin: 0 0 10rem 0;
    flex-direction: column;
    width: 100%;
}

.heading{
    text-align: center;
}

.photoList{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    line-height: 1;
}

.photoItem{
    margin-right: 1rem;
    display: flex
}

.button{
    @extend %button-animate;
    background-color: transparent;
}

.beTheFirst{
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.cameraIcon{
    fill: var(--white)
}

