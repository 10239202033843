

.block{
    position: relative;
    display: block;
    cursor: zoom-out;
}

.active{
    cursor: zoom-in;
}

.result{
    position: absolute;
    inset: 0;
    display: block;
}

.show{
    display: block !important;
}

